import {
  Link,
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from '@remix-run/react'
import type { ErrorResponse } from '@remix-run/router'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import NProgress from 'nprogress'
import { clientOnly$ } from 'vite-env-only/macros'
import { Header } from '~/components/Header'

type StatusHandler = (info: {
  error: ErrorResponse
  params: Record<string, string | undefined>
}) => JSX.Element | null

type GenericErrorBoundaryProps = {
  defaultStatusHandler?: StatusHandler
  statusHandlers?: Record<number, StatusHandler>
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}

function DefaultErrorHandler({ error }: { error: ErrorResponse }) {
  return (
    <div className="my-6 w-full mx-auto home">
      <Header />
      <main className="grid min-h-full px-6 py-24 bg-white place-items-center sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-blue-600">
            {error.status}
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-stone-900 sm:text-5xl">
            Oh no! We have a problem
          </h1>
          <p className="mt-6 text-xl leading-7 text-stone-600">
            We're really sorry about this. It looks like something has broken.
            Don't worry, our team has been notified and we'll be working on a
            fix in no time. Reach out to customer support if you would like us
            to work with you directly.
          </p>
          <div className="flex items-center justify-center mt-10 gap-x-6">
            <Link
              to="/directory/"
              className="rounded-md no-underline bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Visit the Directory
            </Link>
            <a
              href="https://helpdesk.loungepair.com/help/3983236506"
              className="text-sm font-semibold no-underline text-stone-900"
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </div>
  )
}

export function GenericErrorBoundary({
  statusHandlers,
  defaultStatusHandler = ({ error }) => <DefaultErrorHandler error={error} />,
  unexpectedErrorHandler = (error) => <div>{getErrorMessage(error)}</div>,
}: GenericErrorBoundaryProps) {
  const params = useParams()
  const error = useRouteError()
  if (typeof document !== 'undefined') {
    captureRemixErrorBoundaryError(error)
  }

  clientOnly$(NProgress.done())

  return (
    <div>
      {isRouteErrorResponse(error)
        ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
            error,
            params,
          })
        : unexpectedErrorHandler(error)}
    </div>
  )
}

function getErrorMessage(err: unknown) {
  let error: string
  switch (typeof err) {
    case 'string': {
      error = err
      break
    }
    // biome-ignore lint/suspicious/noFallthroughSwitchClause: We want to fall through if the if statement doesn't evaluate
    case 'object': {
      if (err && 'message' in err && typeof err.message === 'string') {
        error = err.message
        break
      }
    }
    default: {
      console.error('Unable to get error message for error:', err)
      error = 'Unknown error'
    }
  }

  return (
    <div className="my-6 w-full mx-auto home">
      <Header />
      <main className="grid min-h-full px-6 py-24 bg-white place-items-center sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-stone-900 sm:text-5xl">
            Oh no! We have a problem
          </h1>
          <p className="mt-6 text-xl leading-7 text-stone-600">
            We're really sorry about this. It looks like something has broken.
            Don't worry, our team has been notified and we'll be working on a
            fix in no time. Reach out to customer support if you would like us
            to work with you directly.
          </p>

          <div className="flex items-center justify-center mt-10 gap-x-6">
            <Link
              to="/directory/"
              className="rounded-md no-underline bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Visit the Directory
            </Link>
            <a
              href="https://helpdesk.loungepair.com/help/3983236506"
              className="text-sm font-semibold no-underline text-stone-900"
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </div>
  )
}
